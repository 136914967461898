<template>
    <div>
        <div v-if="all_sales_leads_loading_satus == true" class="text-center">
            <b-spinner small type="grow"></b-spinner>
            Loading member requests...
        </div>
        <div style="text-align: center;" v-show="!all_sales_leads_loading_satus && all_sales_leads.length == 0">
            <h2>There are no pending requests</h2>
        </div>
        <div v-if="!all_sales_leads_loading_satus && all_sales_leads.length > 0">
            <div class="mb-2 d-flex justify-content-end">
                <button type="button" class="btn btn-success mb-2 mr-2" @click="exportToExcel('salesLeadsTable')">
                    <i class="mdi mdi-file-excel mr-1"></i> Export to Excel
                </button>
                <button v-show="selectedRequests.length > 0" type="button" class="btn btn-primary mb-2 mr-2" @click="createSelectedMembers()">
                    <i class="mdi mdi-account-convert-outline mr-1"></i> Authorise Membership
                </button>
                <button v-show="pendingCompletion.length > 0" type="button" class="btn btn-primary mb-2 mr-2" @click="sendReminders()">
                    <i class="mdi mdi-account-account-clock-outline mr-1"></i> Send Reminder Emails 
                </button>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <webix-ui :config="ui" v-model="all_sales_leads"></webix-ui>
                    <div id="salesLeadsTablePaging"></div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { crmMethods, crmComputed } from "@/state/helpers";
import Swal from "sweetalert2";
import _ from "lodash";

export default {
    data: () => ({ 
        show_modal_pricing: false,
        selectedRequests: [],
        pendingCompletion: [],
    }),
    computed: {
        ...crmComputed,
        ui() {
            //eslint-disable-next-line
            let el = this;
            //eslint-disable-next-line
            let ui = {
                id: "salesLeadsTable",
                view: "datatable",
                css: "datatableUsers webix_header_border webix_data_border",
                autoheight: true,
                minHeight: 60,
                //minColumnWidth: 120,
                scrollY: false,
                scrollX: true,
                resizeColumn: true,
                select: false,
                pager: {
                    template: "{common.prev()} {common.pages()} {common.next()}",
                    container: "salesLeadsTablePaging",
                    size: 20,
                    group: 5,
                },
                columns: [
                    {id: "checkbox", header:{ content:"masterCheckbox" }, checkValue:'on', uncheckValue:'off', template:"{common.checkbox()}", width: 40},
                    {
                        id: "actions",
                        width: 120,
                        header: { text: "Actions", css: { "text-align": "center" } },
                        template: function (obj) {
                            if (obj.conversion_status.toLowerCase() == "pending") {
                                return `<div class="tableIcons">
                                            <i class="mdi mdi-trash-can deleteAction"></i>&nbsp;<i class="mdi mdi-account-convert-outline createMember"></i>
                                        </div>`;
                            } else if (obj.conversion_status.toLowerCase() == "completion mail sent") {
                                return `<div class="tableIcons">
                                            <i class="mdi mdi-trash-can deleteAction"></i>&nbsp;<i class="mdi mdi-account-clock sendReminder"></i>
                                        </div>`;
                            } else {
                                return `<div class="tableIcons">
                                            <i class="mdi mdi-trash-can deleteAction"></i>
                                        </div>`;
                            }
 
                        },
                    },
                    {
                        id: "first_name",
                        header: ["First Name", { content: "textFilter" }],
                        tooltip: false,
                        width: 150,
                        sort: "text",
                    },
                    {
                        id: "last_name",
                        header: ["Last Name", { content: "textFilter" }],
                        tooltip: false,
                        width: 150,
                    },
                    {
                        id: "conversion_status",
                        header: ["Conversion Status", { content: "textFilter" }],
                        tooltip: false,
                        width: 180,
                    },
                    {
                        id: "contact_no",
                        header: ["Contact No", { content: "textFilter" }],
                        tooltip: false,
                        width: 150,
                    },
                    {
                        id: "email",
                        header: ["Email", { content: "textFilter" }],
                        tooltip: false,
                        width: 200,
                    },
                    {
                        id: "branch_name",
                        header: ["Branch", { content: "textFilter" }],
                        tooltip: false,
                        width: 200,
                    },
                    {
                        id: "province",
                        header: ["Province", { content: "selectFilter" }],
                        tooltip: false,
                        width: 150,
                    },
                    {
                        id: "city",
                        header: ["City", { content: "textFilter" }],

                        tooltip: false,
                        width: 150,
                    },
                    {
                        id: "date_of_birth",
                        header: ["Birthday", { content: "textFilter" }],

                        tooltip: false,
                        width: 150,
                    },
                    {
                        id: "id_no",
                        header: ["ID No.", { content: "textFilter" }],

                        tooltip: false,
                        width: 150,
                    },
                    {
                        id: "newsletter",
                        header: ["Newsletter", { content: "selectFilter" }],
                        tooltip: false,
                        width: 150,
                    },
                ],
                on: {
                    onCheck:function(rowId, colId, state){
                        let findIndex = _.findIndex(el.selectedRequests, function(o) { return o == rowId; });
                        let findIndex2 = _.findIndex(el.pendingCompletion, function(o) { return o == rowId; });
                        let item = this.getItem(rowId);
                        if (state == "on") {
                            if (findIndex == -1 || findIndex2 == -1) {
                                if (item.conversion_status.toLowerCase() == "pending") {
                                    el.selectedRequests.push(rowId);
                                }
                                if (item.conversion_status.toLowerCase() == "completion mail sent") {
                                    el.pendingCompletion.push(rowId);
                                }
                            }
                        } else {
                            if (findIndex > -1 || findIndex2 > -1) {
                                if (item.conversion_status.toLowerCase() == "pending") {
                                    el.selectedRequests.splice(findIndex, 1);
                                }
                                if (item.conversion_status.toLowerCase() == "completion mail sent") {
                                    el.pendingCompletion.splice(findIndex2, 1);
                                }
                            }
                        }
                    }
                },
                onClick: {
                    //eslint-disable-next-line
                    createMember: function (id, e, trg) {
                        let item = this.getItem(e.row);
                        el.createMember(item);
                    },
                    //eslint-disable-next-line
                    deleteAction: function (id, e, trg) {
                        let item = this.getItem(e.row);
                        el.handleSwal(item, e);
                    },
                    //eslint-disable-next-line
                    sendReminder: function (id, e, trg) {
                        let item = this.getItem(e.row);
                        let params = {
                            lead_ids: [item.id],
                        };
                        el.sendMembershipReminder(params).then(() => {
                            Swal.fire("Reminder mail sent!", "", "success");
                        });
                    },
                },
            };
            return ui;
        },
    },
    methods: {
        ...crmMethods,
        exportToExcel(id) {
            /* eslint-disable */
            return webix.toExcel($$(id), {
                ignore: { actions: true },
                filename: _.uniqueId("members_export_"), // for filename
                name: "Member Requests", // for sheet name
            });
            /* eslint-enable */
        },
        createMember(item) {
            Swal.fire({
                title: `Authorise Membership Application For`,
                text: `${item.first_name}` + " " + `${item.last_name}` ,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes!",
            }).then((result) => {
                if (result.value) {
                    this.completeMembership({ lead_ids: [item.id] }).then(() => {
                        Swal.fire("Conversion Initiated", "", "success");
                        this.setAllSalesLeads();
                        this.selectedRequests = [];
                    });
                }
            });
        },
        sendReminders() {
            Swal.fire({
                title: `Send reminder emails to`,
                text: this.pendingCompletion.length + " " + "members",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes!",
            }).then((result) => {
                if (result.value) {
                    let params = {
                        lead_ids: this.pendingCompletion,
                    };
                    this.sendMembershipReminder(params).then(() => {
                        Swal.fire("Reminder mail sent!", "", "success");
                    });
                }
            });
        },
        createSelectedMembers() {
            Swal.fire({
                title: `Authorise Membership Applications For`,
                text: this.selectedRequests.length + " " + "members",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes!",
            }).then((result) => {
                if (result.value) {
                    this.completeMembership({ lead_ids: this.selectedRequests }).then(() => {
                        Swal.fire("Conversion Initiated", "", "success");
                        this.setAllSalesLeads();
                        this.selectedRequests = [];
                    });
                }
            });
        },
        handleSwal(item) {
            Swal.fire({
                title: `Delete Membership Request?`,
                text: `${item.first_name}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete!",
            }).then((result) => {
                if (result.value) {
                    this.deleteSalesLead({ id: item.id }).then(() => {
                        Swal.fire("Membership Request Deleted", "", "success");
                        this.setAllSalesLeads();
                        this.selectedRequests = [];
                    });
                }
            });
        },
    },
    mounted() {
        this.setAllSalesLeads();
    },
};
</script>

<style lang="scss">
.pointer {
    cursor: pointer;
}
</style>
